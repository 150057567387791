import { FC } from 'react';
import {
  Trello as TrelloIcon,
  Calendar as CalendarIcon,
  IconProps,
} from 'react-feather';
import { DashBoardRelatorios as DashBoardRelatoriosIcon } from '@cigam/template/dist/components/Icons/DashBoardRelatorios';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import BallotIcon from '@mui/icons-material/Ballot';
import TaskIcon from '@mui/icons-material/Task';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { MODULE } from 'src/config';

interface Item {
  title: string;
  href: string;
  icon?: FC<IconProps> | OverridableComponent<SvgIconTypeMap>;
  direito?: string;
  invisible?: boolean;
  items?: Item[];
}

export interface INavConfig {
  moduleName: string;
  nav: {
    subheader: string;
    items: Item[];
  }[];
}

export const navConfig: INavConfig[] = [
  {
    moduleName: '/portalservicos',
    nav: [
      {
        subheader: 'Gerenciamento de Equipe',
        items: [
          {
            title: 'Kanban',
            href: `${MODULE.portalservicos.location}/Kanban`,
            icon: TrelloIcon,
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalclienteservicos',
    nav: [
      {
        subheader: 'PORTAL CLIENTE/SERVIÇOS',
        items: [
          {
            title: "Minhas OS's",
            icon: BallotIcon,
            href: `${MODULE.portalclienteservicos.location}/MinhasOrdensServico`,
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalprojetos',
    nav: [
      {
        subheader: 'Gestão de Projetos',
        items: [
          {
            title: 'Agenda de Técnicos',
            href: `${MODULE.portalprojetos.location}/AgendaTecnicos`,
            icon: CalendarIcon,
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalindustria',
    nav: [
      {
        subheader: 'Portal Indústria',
        items: [
          {
            title: 'Leitor de produção',
            icon: CenterFocusStrongIcon,
            href: `${MODULE.portalindustria.location}/LeitorProducao`,
          },
          {
            title: 'Movimentos de produção',
            icon: SyncAltIcon,
            href: `${MODULE.portalindustria.location}/MovimentosProducao`,
          },
          {
            title: 'Pesquisa Ordens de Produção',
            icon: SearchIcon,
            href: `${MODULE.portalindustria.location}/PDOrdensProducao`,
            direito: 'MNPCPESMOV',
          },
          {
            title: 'Dashboards/Relatórios',
            icon: DashBoardRelatoriosIcon,
            href: '/',
            items: [
              {
                title: 'Emissão da OP',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06464/Emissão da OP/PCIMOP`,
              },
              {
                title: 'Emissão de Talão',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06465/Emissão de Talão/PCIMTA`,
              },
              {
                title: 'Previsto X Realizado por OP',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06178/Previsto X Realizado por OP/6178`,
              },
              {
                title: 'Previsto x Realizado por Material',
                href: `${MODULE.portalindustria.location}/Relatorios/CG06172/Previsto X Realizado por Material/6172`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    moduleName: '/portalcompras',
    nav: [
      {
        subheader: 'Portal Compras',
        items: [
          {
            title: 'Inteligência de Negócios',
            icon: AssessmentIcon,
            href: `${MODULE.portalcompras.location}/InteligenciaNegocios`,
          },
          {
            title: 'Notas de Entrada',
            icon: FileOpenIcon,
            href: `${MODULE.portalcompras.location}/PDNotasEntrada`,
            direito: 'COENTRA_P',
          },
          {
            title: 'Ordens de Compras',
            icon: LocalMallIcon,
            href: `${MODULE.portalcompras.location}/PDOrdensCompras`,
            direito: 'COORDEMC_P',
          },
          {
            title: 'Solicitações de Compras',
            icon: AddShoppingCartIcon,
            href: `${MODULE.portalcompras.location}/PDSolicitacoesCompras`,
            direito: 'MNCOMOV',
          },
          {
            title: 'Pré Nota',
            icon: TaskIcon,
            href: `${MODULE.portalcompras.location}/PDPreNota`,
            direito: 'PN312QWT98',
          },
          {
            title: 'Gerenciamento XML',
            icon: CloudSyncIcon,
            href: `${MODULE.portalcompras.location}/PDGerenciamentoXML`,
          },
          {
            title: 'Dashboards/Relatórios',
            icon: DashBoardRelatoriosIcon,
            href: '/',
            items: [
              {
                title: 'Ordens de Compras em aberto',
                href: `${MODULE.portalcompras.location}/Relatorios/CG00958/Ordens de Compras em aberto/958`,
              },
              {
                title: 'Compras por Fornecedor',
                href: `${MODULE.portalcompras.location}/Relatorios/CG02623/Compras por Fornecedor/2623b`,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    moduleName: '/portalcliente',
    nav: [
      {
        subheader: 'ÁREA FINANCEIRA',
        items: [
          {
            title: 'Boletos',
            icon: ReceiptIcon,
            href: `${MODULE.portalcliente.location}/Boletos`,
          },
        ],
      },
    ],
  },
];
